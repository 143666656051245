import { AuthProvider } from '@redwoodjs/auth'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import ReactDOM from 'react-dom'
import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'

import './i18n'

import FatalErrorPage from 'src/pages/FatalErrorPage'

import './index.css'
import 'src/ui/Theme/default.scss'
import store from 'src/store'
import { Provider } from 'react-redux'
import React from 'react'

import App from 'src/System/App'
import theme from 'src/theme'

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
}

const firebaseClient = ((config) => {
  firebase.initializeApp(config)
  return firebase
})(config)

const link = onError(({ graphQLErrors, networkError }) => {
  alert('link works')
  console.log('#YOU^FAILED#', graphQLErrors, networkError)
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `#YOU^FAILED#[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  if (networkError) console.log(`#YOU^FAILED#[Network error]: ${networkError}`)
})

const graphqlConfig = {
  link, //: link.concat(createHttpLink({ uri: 'http://localhost:4000/graphql' })),
  // cache: new InMemoryCache(),
}

ReactDOM.render(
  <FatalErrorBoundary page={FatalErrorPage}>
    <AuthProvider client={firebaseClient} type="firebase">
      <RedwoodProvider graphQLClientConfig={graphqlConfig}>
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <App />
          </Provider>
        </MuiThemeProvider>
      </RedwoodProvider>
    </AuthProvider>
  </FatalErrorBoundary>,
  document.getElementById('redwood-app')
)
