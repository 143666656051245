import React, { useEffect, ReactNode } from 'react'
import { useAuth } from '@redwoodjs/auth'
import { useDispatch, useSelector } from 'react-redux'

import Routes from 'src/Routes'

/**
 * IMPORTANT
 * Store is updated here if user is logged in, we need some configuration.
 * @constructor
 */
const App = (): ReactNode => {
  const { getCurrentUser } = useAuth()
  const configuration = useSelector(({ configuration }) => configuration)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!configuration) {
      getCurrentUser().then(({ configuration }) => {
        dispatch({ type: 'configuration', configuration })
      })
    }
  }, [configuration, dispatch, getCurrentUser])
  return <Routes />
}

export default App
