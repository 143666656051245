// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Route, Private, Router } from '@redwoodjs/router'

const Routes = () => (
  <Router>
    <Route path="/aurevoir" page={AurevoirPage} name="aurevoir" />
    <Route path="/select" page={DemoSelectPage} name="select" />
    <Route path="/" page={HomePage} name="home" />
    <Route notfound page={NotFoundPage} />
    <Private unauthenticated={'home'}>
      <Route path="/books" page={BooksPage} name="books" />
      <Route path="/community" page={CommunityPage} name="community" />
      <Route path="/contacts" page={ContactsPage} name="contacts" />
      <Route path="/dashboard" page={DashboardPage} name="dashboard" />
      <Route path="/label/{id}" page={LabelPage} name="label" />
      <Route path="/labels" page={LabelsPage} name="labels" />
      <Route path="/ownership/{id}" page={OwnershipPage} name="ownership" />
      <Route path="/profil" page={ProfilPage} name="profil" />
      <Route path="/user/{id}" page={UserPage} name="user" />
    </Private>
  </Router>
)

export default Routes
