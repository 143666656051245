import { createStore } from 'redux'

function reducer(state, { type, ...action }) {
  let _s = {}
  if ('notify' === type) {
    _s = { notifications: [action.notification] }
  }
  if ('configuration' === type) {
    _s = { configuration: action.configuration }
  }
  return { ...state, ..._s }
}

const store = createStore(
  reducer,
  { configuration: undefined, notifications: [] },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
export default store
