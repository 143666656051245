import { createMuiTheme } from '@material-ui/core/styles'

export const Red = '#a23a37'
export const Gold = '#d6ab67'
export const DarkGold = '#bd7741'
export const Green = '#93a89e'
export const DarkGreen = '#6a8d77'

export const DeepRed = '#892332'
export const LightGrey = '#999'
export const ColdGrey = '#555'
export const WarmGrey = '#333'
export const LightSeashell = '#fff9ee'
export const TaupeBlue = '#2b6cb0'

const theme = createMuiTheme({
  palette: { primary: { main: Red }, secondary: { main: Green } },
  typography: {
    fontFamily: 'Roboto',
    fontSize: 10,
    h1: { color: Gold },
    h3: { color: DarkGold },
    h4: { color: Green },
    subtitle1: { color: WarmGrey },
    body2: { color: WarmGrey },
  },
  overrides: {
    MuiAvatar: {
      root: { position: null },
      colorDefault: { backgroundColor: 'transparent' },
    },
    MuiButton: { root: { margin: null } },
    MuiButtonBase: { root: { margin: null } },
    MuiCard: { root: { padding: '1rem', margin: '0.5rem' } },
    MuiLink: { root: { color: Red } },
    MuiSpeedDial: {
      directionUp: {
        bottom: '2rem',
        right: '2rem',
      },
    },
    MuiTypography: {
      root: {
        margin: 4,
      },
      // variantMapping: { h2: 'h3', body2: 'span' },
    },
    MuiSnackbarContent: {
      root: { backgroundColor: 'unset' },
    },
    MuiPaper: {
      root: {
        backgroundColor: LightSeashell,
        color: DarkGreen,
      },
      elevation1: {
        boxShadow: 'none',
        borderWidth: 1,
        borderColor: DeepRed,
        borderStyle: 'dashed',
      },
      /** AppBar removal of shadow. */
      elevation4: { boxShadow: 'none' },
    },
  },
})

export default theme
